<template>
  <v-app id="inspire" style="background: #f8f9fb">
    <v-main class="image">
      <v-container class="fill-height">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" md="6">
            <app-header />
            <v-card class="ma-auto pa-5 elevation-5" max-width="500">
              <v-form v-model="valid" class="px-10">
                <v-text-field
                  class="my-5"
                  label="Email"
                  name="email"
                  id="email"
                  prepend-icon="mdi-email"
                  type="text"
                  v-model="email"
                  :rules="[required('email'), emailFormat()]"
                ></v-text-field>

                <v-text-field
                  class="mb-3"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  id="password"
                  label="Password"
                  name="password"
                  prepend-icon="mdi-lock"
                  v-model="password"
                  @click:append="show = !show"
                  :rules="[required('password'), minLength('password', 8)]"
                  counter="true"
                ></v-text-field>
                <router-link to="/forgotpassword">
                  <span class="caption c-blue-text tw-text-base" justify="end">
                    Forgot password
                  </span>
                </router-link>
              </v-form>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  block
                  large
                  id="signIn"
                  color="secondary"
                  class="my-5"
                  @click="onSubmit"
                  :loading="loading"
                  :disabled="!valid"
                >
                  Login
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <app-bottom />
  </v-app>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import validation from '@/utils/Validations'

export default {
  components: {
    appHeader: () => import('@/components/Logo'),
    appBottom: () => import('@/components/BottomImage'),
  },
  props: {
    source: String,
  },
  data() {
    return {
      valid: false,
      email: '',
      password: '',
      show: false,
      ...validation,
    }
  },
  created() {
    this.$store.commit('auth/setNewPassword', '')
  },
  computed: {
    alertError() {
      return this.$store.getters.alertError
    },
    ...mapState(['loading']),
    ...mapState('auth', ['createUser']),
    ...mapGetters('auth', ['hasAuthenticationStatus', 'authenticationStatus']),
  },
  methods: {
    async onSubmit() {
      this.$store.state.loading = true
      if (!this.valid) return;

      this.$store.commit('SET_LOADING', true);

      try {
        const formData = {
          username: this.email,
          password: this.password,
          email: this.email,
        };

        await this.$store.dispatch('auth/signIn', formData);
        this.$store.state.loading = false

        if (this.createUser && this.createUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.$router.push({ name: 'CreatePassword' });
        } else if (this.hasAuthenticationStatus) {
          if (this.authenticationStatus.variant === 'error') {
            this.$store.commit('SNACKBAR', this.authenticationStatus)
          } else {
            this.$router.push({ name: 'ConfirmLoginOTP', query: { email: this.email } });
          }
        }
      } catch (error) {
        this.showSnackbar('An error occurred during login. Please try again.', 'error');
      } finally {
        this.$store.commit('SET_LOADING', false);
      }
    },
    showSnackbar(text, color = 'error') {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    },
  },
}
</script>

